/* eslint-disable no-unused-expressions */

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};

// Sets custom scroll position depending on the circumstances
export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  // Scroll to the top when linking to/from these pages
  const scrollPages = ["programme", "speakers"];

  // Link is on a scroll page
  const linkIsOnScrolPage = prevRouterProps.location.pathname
    .split("/")
    .some((param) => scrollPages.includes(param));

  // Link is to a scroll page
  const linkIsToScrollPage = routerProps.location.pathname
    .split("/")
    .some((param) => scrollPages.includes(param));

  // Scroll to the top of a scroll page is involved
  if (linkIsOnScrolPage || linkIsToScrollPage) {
    return true;
  }
  // Otherwise don't scroll to the top of page
  return false;
};
