/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { IntlContextConsumer, useIntl } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, meta, title }) {
  const intl = useIntl();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  return (
    <IntlContextConsumer>
      {({ language: currentLocale }) => (
        <Helmet
          title={title}
          titleTemplate={`%s | ${site.siteMetadata.title}`}
          meta={[
            {
              name: `description`,
              content: intl.formatMessage({ id: "description" }),
            },
            {
              property: `og:title`,
              content: intl.formatMessage({ id: "title" }),
            },
            {
              property: "og:image",
              content: `${site.siteMetadata.siteUrl}/images/social/thumb-summit-${currentLocale}.png`,
            },
            {
              property: `og:description`,
              content: intl.formatMessage({ id: "description" }),
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: "og:url",
              content: `${site.siteMetadata.siteUrl}`,
            },
            {
              name: `twitter:card`,
              content: `summary_large_image`,
            },
            {
              name: `twitter:creator`,
              content: "@ilo",
            },
            {
              name: "twitter:image",
              content: `${site.siteMetadata.siteUrl}/images/social/thumb-summit-${currentLocale}.png`,
            },
            {
              name: "twitter:site",
              content: "@ilo",
            },
            {
              name: `twitter:title`,
              content: intl.formatMessage({ id: "title" }),
            },
            {
              name: `twitter:description`,
              content: intl.formatMessage({ id: "description" }),
            },
          ].concat(meta)}
        />
      )}
    </IntlContextConsumer>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
